import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";

const Recruitment = () => (
  <StaticQuery
    query={graphql`
      query {
        veteranen1: file(relativePath: { eq: "veteranen-1.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        veteranen2: file(relativePath: { eq: "veteranen-2.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        veteranen3: file(relativePath: { eq: "veteranen-3.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        veteranen4: file(relativePath: { eq: "veteranen-4.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Helmet>
          <title>Veteraneninstituut | Charlotte de Hilster</title>
        </Helmet>
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr 45%;
            grid-column-gap: 24px;
            width: 100%;
            @media only screen and (max-width: 600px) {
              grid-template-columns: 100%;
            }
          `}
        >
          <div
            css={css`
              @media only screen and (max-width: 600px) {
                grid-row: 2;
              }
            `}
          >
            <Img
              style={{
                marginBottom: `16px`
              }}
              fluid={data.veteranen1.childImageSharp.fluid}
            />
            <Img
              style={{
                marginBottom: `16px`
              }}
              fluid={data.veteranen2.childImageSharp.fluid}
            />
            <Img
              style={{
                marginBottom: `16px`
              }}
              fluid={data.veteranen3.childImageSharp.fluid}
            />
            <Img fluid={data.veteranen4.childImageSharp.fluid} />
          </div>
          <div
            css={css`
              @media only screen and (max-width: 600px) {
                grid-row: 1;
              }
            `}
          >
            <h3
              css={css`
                font-family: "Lato", sans-serif;
                font-weight: bold;
                font-size: 32pt;
                @media only screen and (max-width: 600px) {
                  font-size: 24pt;
                }
              `}
            >
              Veteraneninstituut responsive website
            </h3>
            <p
              style={{
                fontFamily: `Lato, sans-serif`,
                fontWeight: `lighter`,
                fontSize: `14pt`
              }}
            >
              Het Veteraneninstituut is er voor veteranen en iedereen die een
              veteraan kent of het een interessant onderwerp vindt.
              <br />
              <br />
              De toenmalige website van het Veteraneninstituut voldeed niet meer
              aan de behoeften die ze hadden. Op de nieuwe website van het
              Veteraneninstituut wilden zij zich namelijk verder
              professionaliseren en laten zien dat veel kennis in huis hebben
              omtrent veteranen. Dat kon op hun toenmalige website niet. Hij was
              te beperkt voor de vele informatie die zij willen delen.
              <br />
              <br />
              Tijdens een workshop met de opdrachtgever heb ik achterhaald wie
              de primaire doelgroep is van het Veteraneninstuut, wat absoluut
              niet mag ontbreken op de nieuwe website, welke doelen er behaald
              moeten worden en wat er beter kon op hun toenmalige website.
              Vervolgens ben ik met deze informatie aan de slag gegaan. Door het
              maken van een informatie architectuur heb ik de navigatie,
              pagina's en acties in kaart gebracht. Vervolgens heb ik met de
              wireframes de basis van de website neergezet waarna de designer er
              iets moois van kon maken.
              <br />
              <br />
              Daar hielden mijn werkzaamheden niet op. Tijdens de ontwikkeling
              heb ik regelmatig meegekeken met de developers om samen het beste
              resultaat neer te zetten.
              <br />
              <br />
              <a
                css={css`
                  color: #59bfa9;
                `}
                href="https://veteraneninstituut.nl"
                target="_blank"
                rel="noopener noreferrer"
              >
                veteraneninstituut.nl
              </a>
            </p>
          </div>
        </div>
      </Layout>
    )}
  />
);

export default Recruitment;
